var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import DateRangePicker from './DateRangePicker';
var DateRangePickerButton = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, onChangeStartDate = _a.onChangeStartDate, onChangeEndDate = _a.onChangeEndDate, onClose = _a.onClose, isSelected = _a.isSelected, isError = _a.isError, open = _a.open, onOpen = _a.onOpen, shortLabel = _a.shortLabel, _b = _a.sx, sx = _b === void 0 ? {} : _b;
    return (_jsxs(_Fragment, { children: [_jsx(DateRangePicker, { startDate: startDate, endDate: endDate, onChangeStartDate: onChangeStartDate, onChangeEndDate: onChangeEndDate, open: open, onClose: onClose, isSelected: isSelected, isError: isError, variant: "calendar", onChangeOnApproved: true }), _jsx(Button, __assign({ variant: "contained", onClick: onOpen, sx: __assign({ textWrap: 'nowrap', p: '15px 20px', flexGrow: 1 }, sx) }, { children: isSelected ? shortLabel : 'Select Date' }))] }));
};
export default DateRangePickerButton;
